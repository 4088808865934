<template>
  <q-item class="q-ma-none q-pa-none items-center">
    <!-- <q-item-section class="q-ma-none q-pa-none q-pr-sm">
      <q-item-label @click="dialogSure = true" style="text-decoration: underline; white-space: nowrap; color: black"
        class="cursor-pointer">{{ $t("Profile.logout") }}</q-item-label>
    </q-item-section> -->
    <q-icon class="cursor-pointer q-pr-md" name="logout" @click="dialogSure = true"
      size="24px">
      <q-tooltip anchor="bottom middle" self="top middle">
        {{ $t("Profile.logout") }}
      </q-tooltip>
    </q-icon>
  </q-item>

  <!-- Dialog confirmar log out -->

  <q-dialog persistent v-model="dialogSure">
    <q-card :style="{ 'font-family': settings.typography }" class="q-pa-sm">
      <q-card-actions align="center"> {{ $t("Profile.are-you-sure") }} </q-card-actions>
      <q-card-section class="row justify-center">
        <q-btn class="btn-home q-ma-sm" :label="$t('Profile.yes')" @click.prevent="handleLogout()"
          :style="{ 'background-color': settings.color_accent }" v-close-popup />
        <q-btn class="btn-home q-ma-sm" :label="$t('Profile.no')" :style="{ 'background-color': settings.color_accent }"
          v-close-popup />
      </q-card-section>
    </q-card>
  </q-dialog>

  <!-- Dialog logging out -->

  <q-dialog v-model="logOutDialog">
    <q-card :style="{ 'font-family': settings.typography }">
      <q-card-section class="text-center">
        <div class="text-body1 q-pb-md">{{ $t("Profile.logout-in") }}</div>
        <q-spinner size="40px" />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import axios from "../../../../services/Api";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      dialogSure: false,
      logOutDialog: false,
    };
  },
  methods: {
    // Funcion logout
    async handleLogout() {
      this.logOutDialog = true;
      await axios.get("front-catalog/logout").then(() => {
        this.logout();

        if (this.settings.session_expired_redirect && this.settings.session_expired_redirect != '') {
          window.location.replace(this.settings.session_expired_redirect);
        } else {
          this.$router.push({ name: "Login" });
        }
      });
    },
    ...mapActions("auth", ["logout"]),
  },
  computed: {
    settings() {
      return this.$store.state.settings.settings;
    },
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
};
</script>

<style lang="scss" scoped>
.q-icon {
  color: var(--elements-color-primary) !important;
}
.q-card {
  border-radius: 25px 25px 25px 0px;
}

.btn-home {
  border-radius: 25px 25px 25px 0px;
  height: 48px;
  margin-top: 10px;
  width: 100%;
  text-transform: capitalize;
  color: white;
}
</style>
